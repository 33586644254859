require("./base")

import * as $ from 'jquery'
window.$ = window.jQuery = $

require('./view_loader')
require('jquery-ujs/src/rails')

$(() => {
  ViewLoader.load()
})
